export const api_router = [
  {
    name: "api.login",
    path: "login",
  },
  {
    name: "api.logout",
    path: "logout",
  },
  {
    name: "api.forgot.password",
    path: "forgot-password",
  },
  {
    name: "api.reset.password",
    path: "reset-password",
  },
  {
    name: "api.create.password",
    path: "create-password",
  },
  // Advertisers
  {
    name: "api.buyers",
    path: "buyers",
  },
  {
    name: "api.buyers.detail",
    path: "buyers/:id",
  },
  {
    name: "api.buyers.update",
    path: "buyers/:id",
  },
  {
    name: "api.buyers.approve",
    path: "buyers/:id/approve",
  },
  {
    name: "api.buyer-sales.update",
    path: "buyer-sales/:id",
  },
  // Article
  {
    name: "api.articles",
    path: "projects",
  },
  {
    name: "api.project.update",
    path: "projects/:id",
  },
  {
    name: "api.segments",
    path: "segments",
  },
  {
    name: "api.segment.download_csv",
    path: "segment-files/download",
  },
  {
    name: "api.project.detail",
    path: "projects/:id",
  },
  {
    name: 'api.origin_panels',
    path: 'origin-panels'
  },
  {
    name: 'api.origin_panels.detail',
    path: 'origin-panels/:id'
  },
  {
    name: 'api.origin_panels.update',
    path: 'origin-panels/:id'
  },
  // videos
  {
    name: "api.videos",
    path: "videos",
  },
  {
    name: "api.videos.detail",
    path: "videos/:id",
  },
  {
    name: "api.videos.images",
    path: "videos/images",
  },
  {
    name: "api.videos.update",
    path: "videos/:id",
  },
  // video-comment
  {
    name: "api.video-comment",
    path: "video-comment/:id",
  },
  {
    name: "api.video-interaction-comment",
    path: "promotion-interaction-comments",
  },
  // report
  {
    name: "api.report",
    path: "report",
  },
  {
    name: "api.report.promotion-interaction-report",
    path: "promotion-interaction-report",
  },
  {
    name: "api.report.interaction-ranks",
    path: "report/interaction-ranks",
  },
  {
    name: "api.report.interaction-average",
    path: "report/interaction-average",
  },
  {
    name: "api.buyer.videos_norm",
    path: "report/:buyer_id/videos_norm",
  },
  {
    name: "api.buyer.videos_norm_promotion",
    path: "report/:buyer_id/promotion-video-norm",
  },
  {
    name: "api.youtube.authorization",
    path: "youtube-analytics/authorization-url",
  },
  {
    name: "api.youtube-analytics",
    path: "youtube-analytics",
  },
  {
    name: "api.youtube-audience-rating",
    path: "audience-rating",
  },
  {
    name: "api.videos.detail",
    path: "videos/:id",
  },
  {
    name: "api.videos.download_csv",
    path: "videos/:id/download-csv",
  },
  {
    name: "api.videos.report_file",
    path: "videos/:id/report-file",
  },
  {
    name: "api.video.promotion-image",
    path: "promotion-video/images",
  },
  {
    name: "api.video.image",
    path: "videos/images",
  },
  //report
  {
    name: "api.report",
    path: "report",
  },
  {
    name: "api.report.step3",
    path: "result-interaction-report",
  },
  {
    name: "api.report.cognition",
    path: "report-cognition",
  },
  {
    name: "api.summary_norm",
    path: "report/:buyer_id/videos_norm",
  },
  {
    name: "api.interaction_norm",
    path: "result-interaction-norm",
  },
  // User
  {
    name: "api.users",
    path: "users",
  },
  {
    name: "api.users.status",
    path: "change-status-users",
  },
  {
    name: "api.users.person",
    path: "users/:id",
  },
  {
    name: "api.users.person-update",
    path: "users/:id",
  },
  {
    name: "api.users.resend-pw-mail",
    path: "users/:id/resend-password-mail",
  },
  {
    name: "api.buyers.company-info",
    path: "buyers/:id",
  },
  // User -Admins
  {
    name: "api.admins",
    path: "admins",
  },
  {
    name: "api.users.status",
    path: "change-status-users",
  },
  {
    name: "api.youtube.analytics",
    path: "youtube-analytics",
  },
  {
    name: "api.countries",
    path: "countries",
  },
  {
    name: "api.videos.register",
    path: "videos",
  },
  {
    name: "api.videos.upload_url",
    path: "videos/upload-url",
  },
  {
    name: "api.csv_comment.upload_url",
    path: "comment-files/upload-url",
  },
  {
    name: "api.csv_comment.upload_file",
    path: "comment-csv/upload-file",
  },
  {
    name: "api.whitepaper-subscribers",
    path: "whitepaper/subscribers",
  },
  {
    name: "api.jcp-attributes",
    path: "jcp-attributes",
  },
  {
    name: "report.segment-ranks",
    path: "report/segment-ranks",
  },
  {
    name: "api.category",
    path: "category",
  },
  {
    name: "api.attributes-layer",
    path: "attribute-layers",
  },
  {
    name: "video.segments",
    path: "videos/segments",
  },
  {
    name: "api.report-segments",
    path: "report-segments",
  },
  {
    name: "api.report-segment-attributes",
    path: "attribute/report-segments",
  },
  {
    name: "api.report.promotion-interaction-report-segments",
    path: "promotion-interaction-report-segments",
  },
  {
    name: "api.report-screening",
    path: "report-screening",
  },
  {
    name: "api.report-screening-step1",
    path: "promotion-interaction-report-screening",
  },
  {
    name: "api.video-screening",
    path: "videos/screening",
  },
  {
    name: "api.report.interaction-top",
    path: "report/interaction-top",
  },
  {
    name: "api.segment-files",
    path: "segment-files",
  },
  {
    name: "api.segments",
    path: "segments",
  },
  {
    name: "api.segment-files.download",
    path: "segment-files/download",
  },
  {
    name: "api.segment-files.upload-url",
    path: "segment-files/upload-url",
  },
  {
    name: "api.trend-report",
    path: "trend-report",
  },
  {
    name: "api.video-trend",
    path: "video-trend",
  },
  // tabular
  {
    name : "api.video-tabular.detail",
    path : "video/tabular"
  },
  {
    name : "api.video-tabular-contents.update",
    path : "video/tabular-contents/:id"
  },
  {
    name : "api.video-tabular-line.register",
    path : "video/tabular-line"
  },
  {
    name : "api.video-tabular-line.update",
    path : "video/tabular-line/:id"
  },
  
  {
    name: "api.buyer-market",
    path: "buyer/market",
  },
  {
    name: "api.videos.segment-tabular.insert",
    path: "videos/segment-tabular",
  },
  {
    name: "api.video.segment-tabular",
    path: "video/segment-tabular",
  },
  {
    name : "api.video.top-tabular",
    path : "video/top-tabular"
  },
  {
    name : "api.create.video.top-tabular",
    path : "videos/top-tabular"
  },
  {
    name : "api.video.summary-tabular",
    path : "video/summary-tabular"
  },
  {
    name : "api.create.video.summary-tabular",
    path : "video/summary-tabular"
  },
  {
    name : "api.video.comment-tabular",
    path : "video/comment-tabular"
  },
  {
    name : "api.create.video.comment-tabular",
    path : "video/comment-tabular"
  },
  {
    name : "api.project-item-note",
    path : "project/notes"
  },
  {
    name : "api.project-item-note.create",
    path : "project/note"
  },
  {
    name : "api.project-item-note.update",
    path : "project/note/:id"
  },
  {
    name : "api.video-content-note.create",
    path : "video/note"
  },
  {
    name : "api.video-content-note.update",
    path : "video/note/:id"
  },
  // market 
  {
    name : "api.market.buyer.norm",
    path : "market/buyer/norm"
  },
  {
    name : "api.download.manual",
    path : "manual-file"
  },

  // interaction_icon
  {
    name : "api.interaction-icon",
    path : "interaction-icon"
  },
  // rpa
  {
    name : "api.rpa",
    path : "rpa"
  },
  {
    name: "api.comment.csv",
    path: "comment-csv",
  },
  {
    name: "api.capture-excel.xlsx",
    path: "capture-excel",
  },
  // batch
  {
    name : "api.batch",
    path : "run-batch"
  },
  // Label
  {
    name: "api.labels",
    path: "labels/:buyer_id",
  },
  {
    name: "api.label.create",
    path: "label",
  },
  // Interaction
  {
    name: "api.interactions",
    path: "buyers/:buyer_id/interaction-button",
  },
  {
    name: "api.interaction_questions",
    path: "result_interaction_questions",
  },
  {
    name: "api.rs-feasibility",
    path: "rs-feasibility",
  },
  // intention
  {
    name: "api.intentions",
    path: "intention",
  },
  {
    name: "api.video-intentions",
    path: "videos/intention",
  },
  {
    name: "api.report-intention",
    path: "report-intention"
  },
  {
    name: "api.report-intention-second",
    path: "report-intention-second"
  },
  {
    name: "api.intention-comment",
    path: "intention-comment"
  },
  {
    name: "api.chatgpt-summarization",
    path: "video/chatgpt-summarization"
  },
  // videoCapture
  {
    name: "api.video-capture.post",
    path: "video-capture",
  },
  {
    name: "api.video-capture.get",
    path: "video-capture/:id",
  },
  {
    name: "api.video-capture.capture-upload-url",
    path: "video-capture/:id/capture-upload-url",
  },
  {
    name: "api.video-question",
    path: "video-question/:buyer_id",
  },
  {
    name: "api.report.intention-ranks",
    path: "report/intention-ranks",
  },
  {
    name: "api.video.intention-tabular",
    path: "video/intention-tabular",
  },
  {
    name: "api.video.intention-tabular.insert",
    path: "video/intention-tabular",
  },
  // video comment
  {
    name: "api.video-comments",
    path: "video-comments",
  },
  {
    name: "api.video-intentions-count",
    path: "videos/intention-count",
  },
  // video comment
  {
    name: "api.video-comments",
    path: "video-comments",
  },
  {
    name: "api.video-intentions-count",
    path: "videos/intention-count",
  },
  {
    name: "api.target-file",
    path: "target-file/:user_id",
  },
  {
    name: "api.tableau-token",
    path: "tableau/token/:buyer_id",
  },
  {
    name: "api.tableau-data",
    path: "tableau-data/:buyer_id",
  },
  {
    name: "api.market-research-file",
    path: "market-research-file/:user_id",
  }
];
